.Loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


// @keyframes spinner-outer {
//   to {
//     transform: rotate(360deg);
//   }
// }

// .spinner-outer:before {
//   content: '';
//   box-sizing: border-box;
//   position: absolute;
//   width: 100px;
//   height: 100px;
//   margin-top: -50px;
//   margin-left: -50px;
//   border-radius: 50%;
//   border: 10px solid transparent;
//   border-top-color: #003A70;
//   border-bottom-color: #003A70;
//   animation: spinner-outer 2s ease infinite;
// }

// @keyframes spinner-inner {
//   to {
//     transform: rotate(360deg);
//   }
// }

// .spinner-inner:before {
//   content: '';
//   box-sizing: border-box;
//   position: absolute;
//   width: 64px;
//   height: 64px;
//   margin-top: -32px;
//   margin-left: -32px;
//   border-radius: 50%;
//   border: 9px solid transparent;
//   border-top-color: #5289C3;
//   border-bottom-color: #5289C3;
//   animation: spinner-inner 1.5s ease infinite;
// }

// .spinner-center {
//   position: absolute;
//   top: 79px;
//   border-radius: 50%;
//   border: 12px solid #B8D65B;
// }