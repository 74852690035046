@import "./variables";

a:hover,
a:visited,
a:link,
a:active {
	text-decoration: none !important;
}

.btn-primary {
	color: #FFFFFF !important;
}

.btn-outline-primary:hover {
	color: #FFFFFF !important;
}

.dropdown-item:hover {
	color: #FFFFFF !important;
}

//MULTISELECT OVERRIDES
.chip {
	color: #FFFFFF !important;
}

//BOOTSTRAP TABLE NEXT OVERRIDES
.table a {
	text-decoration: none !important;
}

.table thead {
	border-bottom-width: 2px !important;
	border-bottom-color: map-get($color, "light-grey") !important;
}

//TABS

.tab-content {
	padding: 0 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: map-get($color, "primary") !important;
}

.nav-tabs .nav-link {
	color: map-get($color, "secondary") !important;
}

.nav-link-offcanvas {
	color: #000000 !important;
}

.nav-pills .nav-link {
	border-color: #000000;
	color: #000000 !important;

}

.nav-pills .nav-link.active {
	color: #FFFFFF !important;
}

option {
	background: #ffffff;
	color: map-get($color, "primary") !important;
}

select option:checked,
select option:focus {
	background: map-get($color, "primary") !important;
	-webkit-text-fill-color: #ffffff !important;
	color: #ffffff !important;
}

.lock-icon-disabled,
.print-icon-disabled,
.check-icon-disabled {
	color: map-get($color, "grey");
}


.lock-icon,
.print-icon,
.check-icon {
	color: map-get($color, "primary");
}

.edit-icon:hover,
.lock-icon:hover,
.print-icon:hover {
	color: map-get($color, "secondary") !important;
	transform: scale(1.2) !important;
}

.lock-icon-disabled {
	color: map-get($color, "primary");
	opacity: 0.5 !important;
}

.chip {
	background: map-get($color, "primary") !important;
}

.highlightOption {
	background: map-get($color, "primary") !important;
}

.option:hover {
	background: map-get($color, "secondary") !important;
}

#clearButton {
	color: map-get($color, "primary") !important;
}

#clearButton:hover {
	color: map-get($color, "secondary") !important;
}

.react-bs-table-csv-btn {
	color: map-get($color, "secondary") !important;
}

.react-bs-table-csv-btn:hover {
	color: map-get($color, "primary") !important;
	transform: scale(1.2) !important;
}

//Spinner for RF Loading Icons
.spinner-primary {
	color: map-get($color, "primary") !important;
	animation: spin infinite 1s linear;
}

//Spinner for RF Loading Icons
.spinner {
	color: #FFFFFF !important;
	animation: spin infinite 1s linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.formfield-title {
	color: map-get($color, "grey") !important;
}

.formfield-required::after {
	content: "*";
	padding-left: 2px;
	color: map-get($color, "required") !important;
	display: inline-block;
	text-decoration: none !important;
}

.formfield-title:hover {
	text-decoration: underline !important;
	cursor: pointer;
}

.form-control-plaintext {
	padding: 0 !important;
}

.input-group-plaintext {
	margin-bottom: 0 !important;

	p {
		margin-bottom: 0 !important;
	}
}

//TABLE
.table-row-success {
	background-color: #def1de !important;
	border-color: #ceeace !important;

	td {
		color: #376e37 !important;
	}
}

.table-row-warning {
	background-color: #fcf8e3 !important;
	border-color: #faebcc !important;

	td {
		color: #8a6d3b !important;
	}
}

.table-row-danger {
	background-color: #ffc7c3 !important;
	border-color: #ffc7c3 !important;

	td {
		color: #dc3545 !important;
	}
}


//Knowledge Base
.color-kbnav {
	line-height: 0;
}

.kbnav-item {
	border: none !important;
}

.dropdown-item.active {
	color: #FFFFFF !important;
	font-weight: 600 !important;
}

.kbnav-dropdown {
	font-size: medium;
	line-height: 1.25 !important;
	font-weight: 400;
	padding-bottom: 0 !important;


	//Main items
	a:hover {
		font-weight: 500 !important;
		background-color: #FFFFFF !important;
		color: #000000 !important;
	}

	a {
		font-weight: 500 !important;
		color: #000000 !important;
	}

	.dropdown-toggle:after {
		display: none;
	}

}

.kbnav-dropdown .dropdown-menu {
	border: none !important;

	a {
		font-weight: 100 !important;
	}

	//Sub items
	a:hover {
		background-color: map-get($color, "primary") !important;
		color: #FFFFFF !important;
	}
}

.kb-edit-icon {
	font-size: 18px;
	padding: 0;
	overflow: auto;
	font-weight: 300;
	color: map-get($color, "primary") !important;
	cursor: pointer
}

.kb-edit-icon:hover {
	font-size: 19px;
	color: map-get($color, "secondary") !important;
	font-weight: 600;
}

.kb-ticket-icon {
	font-size: 50px;
	padding: 0;
	overflow: auto;
	font-weight: 300;
	color: map-get($color, "primary") !important;
	cursor: pointer
}

.kb-ticket-icon:hover {
	font-size: 50px;
	color: map-get($color, "secondary") !important;
	font-weight: 600;
}


.rt-wrapper {
	padding: .2rem;
	border-radius: 4px;
	border: 1px solid map-get($color, "light-grey") !important;
}

.rt-editor {
	padding: .2rem;
	border-radius: 4px;
	border: 1px solid map-get($color, "light-grey") !important;
}

.rt-toolbar {
	border: 1px solid map-get($color, "light-grey") !important;
}

.rt-wrapper-hide {
	padding: 0 !important;
	border: none !important;
}

.rt-editor-hide {
	padding: 0;
	border-radius: 4px;
	border: none !important;
}

.rt-toolbar-hide {
	display: none !important;
	border: 1px solid blue !important;
}

//RF BACK TO TOP
#btn-back-to-top {
	color: map-get($color, "primary") !important;
	opacity: 0.60;
	font-size: 35px;
}

.search-dropdown {
	background-color: #000000 !important;
	padding: 100px !important;
	z-index: 999 !important;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: map-get($color, "primary") !important;
}

.nav-link {
	color: #FFFFFF !important;
}

.offcanvas .nav-link {
	color: map-get($color, "dark") !important;
}

.dropdown-toggle,
.nav-link {
	:active {
		background-color: map-get($color, "secondary") !important;
	}
}

.rf-nav {
	padding: 0 .5% 0 .5%;

	.logo {
		.name {
			font-size: 24px;
			margin: 0;
			padding: 0;
			line-height: 2;
			color: map-get($color, "primary") !important;
			font-family: 'TelementumFont';

			@media (max-width: 992px) {
				display: none;
			}
		}

		img {
			max-height: 48px;
		}
	}
}

.ui-nav {
	position: -webkit-sticky;
	z-index: 999;
	position: sticky;
	background-color: #FFFFFF;
	background-image: url('../../../public/nav_bg.png');
	background-repeat: repeat-x;
	margin-bottom: .5%;
	top: 0;

	.top {
		padding: 0;
		margin: 0;

		.brand {
			margin-left: 1%;
			padding: 0;

			.logo {
				img {
					max-height: 50px;
				}
			}

			.name {
				font-size: 24px;
				margin: 0;
				padding: 0;
				color: map-get($color, "primary") !important;
				font-family: 'TelementumFont';

				@media (max-width: 992px) {
					display: none;
				}
			}
		}

		.search {
			width: 33%;
			z-index: 999999 !important;

			@media (max-width: 992px) {
				width: 75%;
			}

			.results {
				width: 33%;

				@media (max-width: 992px) {
					width: 75%;
				}
			}
		}

		.user-menu {
			margin-right: 1%;
			display: flex; // Ensure the button can stretch
			align-items: stretch; // Make the button stretch vertically
			height: 100%; // Define the height of the parent div
			width: 15%;
			max-width: 250px;

			.toggle {
				height: 100% !important;
				width: 100%;
				border-color: #FFFFFF !important;
				background-color: rgba(255, 255, 255, 0.75); // 50% transparent white
			}

			.btn {
				//Overrides Bootstrap <Dropdown.Toggle>
				border: none;
				width: 100%;
				height: 60px !important;
				border-radius: 0%;
				outline: none;
				box-shadow: none;
			}

			.dropdown-menu {
				width: 125%;
			}

			.dropdown-toggle:after {
				display: none; //hides the dropdown arrow
			}

			.dropdown-item:hover,
			.btn:hover {
				background-color: map-get($color, "secondary") !important;
			}

			.name {
				font-size: 13px;
				color: map-get($color, "dark");
				margin: 0;
				padding: 0;
				line-height: 1;
				text-align: left;
			}

			.workstation {
				font-size: 10px;
				color: map-get($color, "dark");
				margin: 0;
				padding: 0;
				line-height: 1;
				text-align: left;
			}

			.icon {
				color: map-get($color, "dark");
				font-weight: normal;
				font-size: 1.75em;
			}

			@media (max-width: 992px) {
				display: none;
			}
		}
	}

	.bottom {
		line-height: 0;
		background-color: map-get($color, "primary") !important;

		.icon {
			font-size: 24px;
			padding: 0;
			overflow: auto;
			font-weight: 600;

			a:hover {
				background-color: map-get($color, "secondary") !important;
			}

			a {
				color: #303030;
			}
		}

		.dropdown {
			font-size: medium;
			line-height: 1.5 !important;
			font-weight: 500;

			a:hover {
				background-color: map-get($color, "secondary") !important;
			}

			a {
				color: #303030;
			}

			.showArrow {
				cursor: pointer;

				.dropdown-toggle:after {
					display: initial !important;
				}
			}

			.dropdown-toggle:after {
				display: none;
			}
		}
	}
}

.access {

	padding: 1%;

	.title {
		text-align: center;
		font-size: 1em;
	}

	.name {
		font-size: larger;
		margin: 0;
		padding: 0;
		line-height: 2;
		color: map-get($color, "primary") !important;
		font-family: 'TelementumFont';

		@media (max-width: 992px) {
			display: none;
		}
	}

	img {
		padding: 0;
		max-width: 88px;
		max-height: 88px;
	}
}

.alert-sm {
	padding: 5px;
	font-size: smaller;
}

@font-face {
	font-family: "TelementumFont";
	src: url('./cynthonext.bold.otf');
}

.offcanvas .nav-link {
	color: map-get($color, "dark") !important;
}

.dropdown-toggle,
.nav-link {
	:active {
		background-color: map-get($color, "secondary") !important;
	}
}